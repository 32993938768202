import { OpenGraphType } from 'next/dist/lib/metadata/types/opengraph-types';
import Head from 'next/head';

export type OpenGraphConfig = Partial<{
  title: string;
  description: string;
  imgSrc: string;
  type: OpenGraphType;
  canonical: string;
  url: string;
  keywords?: string;
  imageInfo?: {
    width: number;
    height: number;
    type: string;
  };
}>;
export const SocialSharingTags = (props: OpenGraphConfig) => {
  const {
    title = 'Electric Cars for Sale',
    imgSrc,
    description = 'Buy New & Pre-Owned Electric Vehicles.',
    canonical,
    url,
    keywords,
    type,
  } = props;
  const domain =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : `https://${process.env.NEXT_PUBLIC_DOMAIN}`;

  const lowercaseDomain = domain ? domain.toLowerCase() : '';

  const defaultImageUrl = lowercaseDomain?.includes('ev.com')
    ? `${domain}/images/og/ev_com_main.png`
    : `${domain}/images/og/ev_ca_main.png`;

  const contentImage = imgSrc || defaultImageUrl;

  return (
    <Head>
      {/* basic tags */}
      <title key="title">{title}</title>
      {canonical && <link rel="canonical" href={canonical} />}
      <meta name="description" content={description} />

      {/* open graph tags */}
      <meta property="og:image" content={contentImage} key="og-image" />
      <meta property="og:image:url" content={contentImage} key="og-image-url" />
      {contentImage.startsWith('https') && (
        <meta
          property="og:image:secure_url"
          content={contentImage}
          key="og-image-secure-url"
        />
      )}
      {props.imageInfo && (
        <>
          <meta
            property="og:image:width"
            content={props.imageInfo.width.toString()}
          />
          <meta
            property="og:image:height"
            content={props.imageInfo.height.toString()}
          />
          <meta property="og:image:type" content={props.imageInfo.type} />
        </>
      )}
      <meta property="og:title" content={title} key="og-title" />
      <meta
        property="og:description"
        content={description}
        key="og-description"
      />
      <meta property="og:type" content={type} key="og-type" />
      {url && <meta property="og:url" content={url} key="og-url" />}

      {/* twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@EVcom" />
      <meta name="twitter:creator" content="@EVcom" />
      <meta name="twitter:title" content={title} key="twitter-title" />
      <meta
        name="twitter:description"
        content={description}
        key="twitter-description"
      />
      <meta name="twitter:image" content={contentImage} key="twitter-image" />
      {keywords && <meta name="keywords" content={keywords} key="keywords" />}
    </Head>
  );
};
